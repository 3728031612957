import React from "react";
import NotFound from "../../assest/img/404.svg";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        className="px-2"
        style={{
          height: "84vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div className="px-2">
          <div className="text-center">
            <img src={NotFound} style={{ width: "60%" }} alt="" />
          </div>
          <div className="th-responsive-header pt-4 th-fw-500 th-30  text-center th-primary-1">
            Page not found
          </div>
          <div className="pt-2 text-center th-text-hint th-18 text-center">
            We're Sorry, the page you requested could not be found.
          </div>
          <div className="pb-2 text-center th-text-hint th-18 text-center">
            Please go back to the homepage.
          </div>
          <div className="text-center">
            <button
              style={{ minWidth: "200px" }}
              onClick={() => {
                navigate("/home");
              }}
              className="th-pointer th-btn-primary text-center th-br-30 py-2 mt-3 th-primary-bg-1"
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;

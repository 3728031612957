import actions from "./actions";

const initialState = {
  id: "",
  name: "",
  role: "",
  contact: "",
  email: "",
  authorized: false,
  loading: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

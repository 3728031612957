import React, { createContext, useState } from "react";

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const openSnackbar = (message, severity) => {
    setMessage(message);
    setIsOpen(true);
    setSeverity(severity);
    setTimeout(() => {
      closeSnackbar();
    }, 2000);
  };

  const closeSnackbar = () => {
    setIsOpen(false);
    setMessage("");
  };

  return (
    <SnackbarContext.Provider
      value={{ isOpen, message, openSnackbar, closeSnackbar, severity }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

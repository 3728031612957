/* eslint-disable no-nested-ternary */
import React from "react";
import PdfjsPreview from "../pdf-js";
import { AttachmentPreviewerContext } from "../attachment-previewer-contexts";
import "./attachment-previewer-ui-styles.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function AttachmentPreviewerUI() {
  const {
    attachments = [],
    closePreview,
    currentAttachmentIndex,
    scaleValue,
    setScaleValue,
    numPages,
    setNumPages,
    pageNumber,
    setPageNumber,
    controls: { isOpen, next, prev, isNextAvailable, isPrevAvailable },
  } = React.useContext(AttachmentPreviewerContext) || {};
  const {
    extension,
    src = "",
    name,
    contentType,
  } = (attachments || [])[currentAttachmentIndex] || {};

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const pptFileSrc = `https://view.officeapps.live.com/op/embed.aspx?src=${src}`;
  const isPPt = String(src.split(".")[src.split(".").length - 1])
    .toLowerCase()
    .includes("ppt");
  const imageFileFormats = [
    ".tif",
    ".tiff",
    ".bmp",
    ".jpg",
    ".jpeg",
    ".gif",
    ".png",
    ".eps",
    ".raw",
    ".cr2",
    ".nef",
    ".orf",
    ".sr2",
  ];
  // const audioVideoFileFormats = [
  //   '.flv',
  //   '.mp4',
  //   '.m3u8',
  //   '.ts',
  //   '.3gp',
  //   '.mov',
  //   '.avi',
  //   '	.wmv',
  //   //
  //   '.wav',
  //   '.mp3',
  //   '.au',
  //   '.snd',
  // ];
  if (!isOpen) return null;

  document.addEventListener(
    "contextmenu",
    function (e) {
      e.preventDefault();
    },
    false
  );

  const previewerUI = (
    <>
      {
        <div>
          <div className="attachment-viewer" key={src}>
            <div className="attachment-viewer-header">
              <div className="attachment-viewer-header-close-icon">
                <i
                  class="th-pointer fas fa-times-circle th-26 text-white"
                  onClick={closePreview}
                  aria-label="close"
                ></i>
              </div>
            </div>
            <div className="attachment-viewer-frames">
              <div className="attachment-viewer-frame">
                <div className="attachment-viewer-frame-preview-wrapper">
                  {imageFileFormats.includes(extension.toLowerCase()) ? (
                    <div className="attachment-viewer-frame-preview attachment-viewer-frame-preview-image-wrapper">
                      <img
                        alt="sd"
                        className="attachment-viewer-frame-preview-image"
                        src={src}
                      />
                    </div>
                  ) : src.toLowerCase().includes(".mp4") ||
                    src.toLowerCase().includes(".mp3") ? (
                    <video
                      id="attachment-iframe"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        height: "80vh",
                        // height: fullscreen ? '92vh' : '69vh',
                      }}
                      controls
                      autoPlay
                      controlsList="nodownload"
                    >
                      {src.toLowerCase().endsWith(".mp4") ? (
                        <source src={src} type="video/mp4" />
                      ) : (
                        <source src={src} type="audio/mp3" />
                      )}
                      {/* <source src='mov_bbb.ogg' type='video/ogg' /> */}
                      Your browser does not support HTML5 video.
                    </video>
                  ) : isPPt ? (
                    <iframe
                      id="attachment-iframe"
                      title="attachment-iframe"
                      src={pptFileSrc}
                      className="attachment-viewer-frame-preview-iframe"
                    />
                  ) : (
                    <div>
                      <Document
                        file={src}
                        className="pdf-document"
                        externalLinkTarget="_blank"
                        onLoadError={(error) =>
                          alert(
                            "Error while loading document! " + error.message
                          )
                        }
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          height={550}
                          scale={scaleValue || 1}
                          width={500}
                          renderAnnotationLayer={true}
                          className="pdf-page"
                          pageNumber={pageNumber}
                        />
                      </Document>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {src.toLowerCase().endsWith(".pdf") && (
              <div
                onClick={() => {
                  if (pageNumber === numPages) return;
                  setPageNumber((prev) => prev + 1);
                }}
                aria-label="right"
                className="th-navigate-page-btn-next th-icon-button d-flex justify-content-center align-items-center"
                style={{
                  outline: "none",
                  background: pageNumber === numPages && "#E6E9EB",
                }}
              >
                <i
                  style={{
                    color: pageNumber !== numPages ? "#143374" : "black",
                  }}
                  className="fas th-22 fa-arrow-right"
                ></i>
              </div>
            )}
            {numPages && (
              <div className="attachment-viewer-buttons">
                {/* <Tooltip title="Zoom Out" placement="left"> */}
                <span>
                  <div
                    onClick={() => {
                      if (scaleValue === 1) return;
                      if (scaleValue > 1) {
                        setScaleValue((prev) => prev - 0.5);
                      }
                    }}
                    aria-label="zoom-out"
                    className="attachment-viewer-zoom-out-btn"
                    size="small"
                  >
                    <i
                      class="fas fa-search-minus th-24"
                      style={{ color: scaleValue === 1 ? "grey" : "black" }}
                    ></i>
                  </div>
                </span>
                {/* </Tooltip> */}
                <div className="attachment-viewer-center-frame">
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </div>
                {/* <Tooltip title="Zoom In" placement="right"> */}
                <span>
                  <div
                    onClick={() => {
                      if (scaleValue === 2.5) return;
                      if (scaleValue < 2.5) {
                        setScaleValue((prev) => prev + 0.5);
                      }
                    }}
                    aria-label="zoom-in"
                    className="attachment-viewer-zoom-in-btn"
                    size="small"
                  >
                    <i
                      class="fas fa-search-plus th-24"
                      style={{ color: scaleValue === 2.5 ? "grey" : "black" }}
                    ></i>
                  </div>
                </span>
                {/* </Tooltip> */}
              </div>
            )}
            {src.toLowerCase().endsWith(".pdf") && (
              <div
                // disabled={pageNumber === 1}
                onClick={() => {
                  if (pageNumber === 1) return;
                  prev();
                  setPageNumber((prev) => prev - 1);
                }}
                aria-label="left"
                className="th-navigate-page-btn th-icon-button d-flex justify-content-center align-items-center"
                style={{
                  outline: "none",
                  background: pageNumber === 1 && "#E6E9EB",
                }}
              >
                <i
                  style={{ color: pageNumber !== 1 ? "#143374" : "black" }}
                  className="fas th-22 fa-arrow-left"
                ></i>
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
  return <>{isOpen ? previewerUI : null}</>;
}

export default AttachmentPreviewerUI;

import "./assest/css/general.css";
import "./assest/css/home.css";
import "./assest/css/form.css";
import "./assest/css/style.scss";
import "./assest/css/style.css";
import "./assest/css/header.css";
import "react-calendar/dist/Calendar.css";
import "react-circular-progressbar/dist/styles.css";
import "./component/UtilComponents/Loader/index.css";
import "./assest/css/theme.less";
import React, { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";
import SideMenuBar from "./component/Layout/SideMenuBar";
import { connect } from "react-redux";
import Snackbar from "./component/UtilComponents/SnackbarUtil/Snackbar";
import NewHeader from "./component/Layout/NewHeader";
import NewFooter from "./component/Layout/NewFooter";
import jwtDecode from "jwt-decode";
import Loader from "./component/UtilComponents/Loader";
import PageNotFound from "./page/pageNotFound";
const mapStateToProps = ({ user, dispatch }) => ({
  dispatch,
  user,
});
let user = JSON.parse(localStorage.getItem("user"));
const routes = [
  {
    path: "/dashboard",
    exact: true,
    Component: lazy(() => import("./page/Dashboard")),
    headerContent: `Welcome, userName`,
  },
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("../App/page/Home")),
  },
  {
    path: "/home",
    exact: true,
    Component: lazy(() => import("../App/page/Home")),
  },
  {
    path: "/login",
    exact: true,
    Component: lazy(() => import("../App/page/Login")),
  },
  {
    path: "/login/:slug/:referralCode/:amount",
    exact: true,
    Component: lazy(() => import("../App/page/Login")),
  },
  {
    path: "/register",
    exact: true,
    Component: lazy(() => import("../App/page/Register")),
  },
  {
    path: "/reset-password",
    exact: true,
    Component: lazy(() => import("../App/page/ResetPassword")),
  },
  {
    path: "/register/:slug/:referralCode/:amount",
    exact: true,
    Component: lazy(() => import("../App/page/Register")),
  },
  {
    path: "/courses",
    exact: true,
    Component: lazy(() => import("../App/page/Courses")),
  },
  {
    path: "/course-overview",
    exact: true,
    Component: lazy(() => import("../App/page/CourseOverview")),
  },
  {
    path: "/module-overview/:id",
    exact: true,
    Component: lazy(() => import("../App/page/ModuleDetails")),
  },
  {
    path: "/expanding",
    exact: true,
    Component: lazy(() => import("../App/page/ExpandingNew")),
  },
  {
    path: "/blogs",
    exact: true,
    Component: lazy(() => import("../App/page/BlogContent")),
  },
  {
    path: "/profile",
    exact: true,
    Component: lazy(() => import("../App/page/Profile")),
  },
  {
    path: "/coming-soon",
    exact: true,
    Component: lazy(() => import("../App/page/ComingSoon")),
  },
  {
    path: "/curriculum",
    exact: true,
    Component: lazy(() => import("../App/page/Curriculum")),
    headerContent: "Biomarked Bootcamp Curriculum",
  },
  {
    path: "/programs",
    exact: true,
    Component: lazy(() => import("../App/page/Programs")),
    headerContent: "My Wellness Programs",
  },
  {
    path: "/programs/:slug",
    exact: true,
    Component: lazy(() => import("../App/page/Programs/ProgramDetails")),
    headerContent: "My Wellness Programs",
  },
  {
    path: "/programs/program-section/:slug",
    exact: true,
    Component: lazy(() => import("../App/page/Programs/ProgramSections")),
    headerContent: "My Wellness Programs",
  },
  {
    path: "/programs/program-lesson/:id",
    exact: true,
    Component: lazy(() => import("../App/page/Programs/ProgramDetails")),
    headerContent: "My Wellness Programs",
  },
  {
    path: "/enroll-program/:slug/:referralCode",
    exact: true,
    Component: lazy(() => import("../App/page/Programs/ReferralProgram")),
    headerContent: "My Wellness Programs",
  },
  {
    path: "/enroll/:slug/:referralCode/:amount/:coupon/:percentage",
    exact: true,
    Component: lazy(() => import("../App/page/Enroll")),
  },
  {
    path: "/enroll-program-pay/:slug/:referralCode/:amount/:coupon/:percentage",
    exact: true,
    Component: lazy(() => import("../App/page/Programs/ReferralProgram")),
    headerContent: "My Wellness Programs",
  },
  {
    path: "/assessment",
    exact: true,
    Component: lazy(() => import("../App/page/Assesment")),
    headerContent: "Biomarked Bootcamp Assesments",
  },
  {
    path: "/earnings",
    exact: true,
    Component: lazy(() => import("../App/page/Earnings")),
    headerContent: `userName's Wealth`,
  },
  {
    path: "/invite",
    exact: true,
    Component: lazy(() => import("../App/page/Invite")),
    headerContent: "Let's Simplify Health For Everyone",
  },
  {
    path: "/account",
    exact: true,
    Component: lazy(() => import("../App/page/Account")),
    headerContent: "Biomarked User Details",
  },
  {
    path: "/team",
    exact: true,
    Component: lazy(() => import("../App/page/MyTeams")),
    headerContent: `userName's Team`,
  },
  {
    path: "/clients",
    exact: true,
    Component: lazy(() => import("../App/page/Clients")),
    headerContent: "Client Dashboard",
  },
  {
    path: "/challenges",
    exact: true,
    Component: lazy(() => import("../App/page/Challenges")),
    headerContent: "Let's Simplify Health For Everyone",
  },
  {
    path: "/challenges/enroll/:slug/:referralCode",
    exact: true,
    Component: lazy(() => import("../App/page/ChallengeEnroll")),
  },
  {
    path: "/leaderboard/:id",
    exact: true,
    Component: lazy(() => import("../App/page/Leaderboard")),
  },
  {
    path: "/events",
    exact: true,
    Component: lazy(() => import("../App/page/Event")),
    headerContent: "Client Dashboard",
  },

  {
    path: "/podcasts",
    exact: true,
    Component: lazy(() => import("../App/page/Podcast")),
    headerContent: "Client Dashboard",
  },
  {
    path: "/manual-money-distribution",
    exact: true,
    Component: lazy(() => import("../App/page/ManualDistribution")),
  },
  {
    path: "/monthly-payout",
    exact: true,
    Component: lazy(() => import("../App/page/MonthlyPayout")),
  },
  {
    path: "/recipes/:id",
    exact: true,
    Component: lazy(() => import("../App/page/Recipes")),
  },
  {
    path: "/open-course/:slug/:id",
    exact: true,
    Component: lazy(() => import("../App/page/Resources")),
  },
  {
    path: "/payment-failed",
    exact: true,
    Component: lazy(() => import("../App/page/paymentFailed")),
    headerContent: "Client Dashboard",
  },
  {
    path: "/payment-success",
    exact: true,
    Component: lazy(() => import("../App/page/paymentSuccessfull")),
    headerContent: "Client Dashboard",
  },
  {
    path: "/store",
    exact: true,
    Component: lazy(() => import("../App/page/Store")),
    headerContent: "Client Dashboard",
  },
  {
    path: "/privacy-policy",
    exact: true,
    Component: lazy(() => import("../App/page/privacyPolicy")),
  },
  {
    path: "/terms",
    exact: true,
    Component: lazy(() => import("../App/page/termsAndConditions")),
  },
  {
    path: "/about",
    exact: true,
    Component: lazy(() => import("../App/page/AboutUs")),
  },
  {
    path: "/wellness-programs",
    exact: true,
    Component: lazy(() => import("../App/page/WellnessProgram")),
  },
  {
    path: "/wellness-program/:slug",
    exact: true,
    Component: lazy(() => import("../App/page/WellnessProgram")),
  },
];

function App(props) {
  const location = useLocation();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        props.dispatch({
          type: "user/LOGOUT",
        });
      } else {
        if (!props.user.authorized) {
          let user = JSON.parse(localStorage.getItem("user"));
          props.dispatch({
            type: "user/SET_STATE",
            payload: { ...user },
          });
        }
      }
    } else {
      if (props.user.authorized) {
        props.dispatch({
          type: "user/LOGOUT",
        });
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s" title="Biomarked"></Helmet>
      <Snackbar />
      <SideMenuBar
        hide={
          location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/home" ||
          // location.pathname.includes("/leaderboard") ||
          location.pathname === "/challenges/enroll" ||
          location.pathname.includes("wellness-program") ||
          location.pathname === "/store" ||
          location.pathname === "/" ||
          location.pathname.includes("/enroll-program") ||
          location.pathname === "/payment-failed" ||
          location.pathname === "/payment-success" ||
          location.pathname.includes("/enroll-program/") ||
          location.pathname.includes("/privacy-policy") ||
          location.pathname.includes("/about") ||
          location.pathname.includes("/terms") ||
          location.pathname.includes("/expanding") ||
          location.pathname === "/reset-password/" ||
          (!routes.map((each) => each.path).includes(location.pathname) &&
            !location.pathname.includes("lesson") &&
            !location.pathname.includes("section") &&
            !location.pathname.includes("module") &&
            !location.pathname.includes("recipes") &&
            !location.pathname.includes("open-course")) ||
          !localStorage.getItem("token")
        }
      >
        {(location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/home" ||
          // location.pathname === "/challenges/enroll" ||
          location.pathname.includes("wellness-program") ||
          location.pathname === "/expanding" ||
          // location.pathname === "/enroll-program" ||
          location.pathname === "/store" ||
          location.pathname === "/" ||
          (!routes.map((each) => each.path).includes(location.pathname) &&
            !location.pathname.includes("lesson") &&
            !location.pathname.includes("section") &&
            !location.pathname.includes("module") &&
            !location.pathname.includes("recipes") &&
            !location.pathname.includes("open-course") &&
            !location.pathname.includes("/privacy-policy") &&
            !location.pathname.includes("/reset-password") &&
            !location.pathname.includes("/about") &&
            !location.pathname.includes("/terms"))) &&
          !location.pathname.includes("/leaderboard") &&
          !location.pathname.includes("enroll") && (
            // !location.pathname.includes("/enroll-program/")
            // &&!location.pathname.includes("enroll-program-pay")
            <NewHeader />
          )}
        <Routes>
          {/* <Route exact path="/home" render={() => <Navigate to="/" />} /> */}
          {routes.map(({ path, Component, exact, headerContent }) => (
            <Route
              path={path}
              key={path}
              exact={exact}
              element={
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname === "/home" ||
        location.pathname === "/" ||
        location.pathname.includes("wellness-program") ? (
          <NewFooter />
        ) : null}
      </SideMenuBar>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(App);

import React from "react";
import { useNavigate } from "react-router-dom";

const MyLink = ({ to, className, key, activeclassname, children }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        key={key}
        className={"th-pointer " + className + " " + activeclassname}
        onClick={() => {
          navigate(to);
        }}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default MyLink;

// USING THIS AS A REPLACEMENT BEACAUSE REACT ROUTER DOM LINK IS NOT WORKING IN PRODUCTION

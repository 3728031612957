import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-tooltip/dist/react-tooltip.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import Axios from "axios";
import { createBrowserHistory } from "history";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "./App/component/UtilComponents/SnackbarUtil/SnackbarContext";
import AttachmentPreviewer from "./App/component/UtilComponents/attachment-previewer";
import App from "./App";
import ScrollToTop from "./App/component/Layout/ScrollToTop";

const prod = window.location.href.includes("https://biomarked.in/");
// Axios.defaults.baseURL = `https://biomarked.trixno.com/`;
Axios.defaults.baseURL = prod
  ? `https://biomarked.trixno.com/`
  : `https://biomarked-dev.trixno.com/`;

Axios.defaults.headers.common.Authorization = localStorage["token"]
  ? `JWT ${localStorage.getItem("token")}`
  : "";
Axios.defaults.headers.post["Content-Type"] = "application/json";
const history = createBrowserHistory();
// middlewared
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <SnackbarProvider>
          <AttachmentPreviewer>
            <App />
          </AttachmentPreviewer>
        </SnackbarProvider>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
export { history };

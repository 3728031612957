import React from "react";
import Logo from "../../../assest/img/Biomarked-White.png";
import YoutubeIcon from "../../../assest/img/youtube-icon.svg";
import LinkedinIcon from "../../../assest/img/LinkedinIcon.png";
import FacebookIcon from "../../../assest/img/facebook-icon.png";
import InstagramIcon from "../../../assest/img/instagram-icon.svg";
import "./index.css";
import { Space } from "antd";
import { useNavigate } from "react-router-dom";
import programList from "../../../page/Home/programs/programList";
const NewFooter = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        className="px-2 py-5 th-footer"
        style={{
          background: "#1A1A1A",
        }}
      >
        <div className="row py-5 text-white pl-3">
          <div className="col-md-2 text-center mb-4">
            <img src={Logo} height={80} />
          </div>
          <div className="col-md-10 row">
            <div className="col-md-3 col-sm-6 col-12 mb-4">
              <h4 className="th-fw-600">Products</h4>
              <div className="" style={{ color: "#ACACAC" }}>
                {programList?.map((each) => (
                  <a
                    style={{ color: "#ACACAC" }}
                    href={`/wellness-program/${each?.slug}`}
                  >
                    <div
                      // onClick={() => {
                      //   navigate(`/wellness-program/${each?.slug}`);
                      // }}
                      className="my-2 th-pointer"
                      key={each?.id}
                    >
                      <span>{each?.id === 1 && "Healthy "}</span>
                      {each?.name}
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12 mb-4">
              <h4 className="th-fw-600">Resources</h4>
              <div className="" style={{ color: "#ACACAC" }}>
                <a
                  style={{ color: "#ACACAC" }}
                  href="/privacy-policy"
                  target="__blank"
                >
                  <div className="my-2">Privacy Policy</div>
                </a>
                <a style={{ color: "#ACACAC" }} href="/terms" target="__blank">
                  <div className="my-2">Terms & Conditions</div>
                </a>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 col-12">
              <h4 className="th-fw-600">Company</h4>
              <div className="" style={{ color: "#ACACAC" }}>
                <a style={{ color: "#ACACAC" }} href="/about" target="__blank">
                  <div className="my-2">About</div>
                </a>
                {/* <div className="my-2">Our Team</div> */}
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <h4 className="th-fw-600 w-100">Follow us on Social Media</h4>
              <div
                className="pb-4 th-fw-300 th-18"
                style={{ color: "#ACACAC" }}
              >
                For Daily Health Tips
              </div>
              <Space wrap>
                <a
                  href="https://www.youtube.com/@biomarkedofficial8326"
                  target="__blank"
                >
                  <img
                    className="pr-2"
                    src={YoutubeIcon}
                    height={30}
                    alt="youtube"
                  />
                </a>
                <a
                  href="https://www.instagram.com/biomarked_official/"
                  target="__blank"
                >
                  <img
                    className="pr-2"
                    src={InstagramIcon}
                    height={30}
                    alt="instagram"
                  />
                </a>
                <a href="https://www.facebook.com/biomarked/" target="__blank">
                  <img
                    className="pr-2"
                    src={FacebookIcon}
                    height={30}
                    alt="facebook"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/biomarked/"
                  target="__blank"
                >
                  <img
                    className=""
                    src={LinkedinIcon}
                    height={30}
                    alt="twitter"
                  />
                </a>
              </Space>
            </div>
          </div>
        </div>
        <div className="text-center  th-fw-600 th-18">
          <span className="th-gradient">#getBiomarked</span>
          <div className="text-white pt-2 th-14">
            &copy; Quaternary Health Private Limited
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewFooter;

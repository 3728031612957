import React from "react";
import "./index.css";
const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: "600px" }}
    >
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;

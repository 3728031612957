import WeightLoss from "../../../assest/img/programImages/weight_loss.png";
import Thyroid from "../../../assest/img/programImages/thyroid_care.png";
import Diabetes from "../../../assest/img/programImages/diabetes_care.png";
import FattyLiver from "../../../assest/img/programImages/fatty_liver.png";
import PCOS from "../../../assest/img/programImages/pcos_care.png";
import Recomposition from "../../../assest/img/programImages/recomposition.png";

const programList = [
  {
    id: 1,
    name: "Weight Loss",
    desc: "Losing weight can be hard, we make it easy for you. No calorie counting/ extreme workouts.",
    image: WeightLoss,
    slug: "healthy-weight-loss-program",
  },
  {
    id: 2,
    name: "PCOS Care",
    desc: "A disorder that can disrupt the harmony of life. Pills will not cure you.",
    image: PCOS,
    slug: "pcos-care-program",
  },
  {
    id: 3,
    name: "Diabetes Care",
    desc: "High blood sugar levels, weight gain and low energy levels. Pills will only manage, not cure.",
    image: Diabetes,
    slug: "diabetes-care-program",
  },
  {
    id: 4,
    name: "Thyroid Care",
    desc: "Low energy, weight gain and hair fall. A pill will only manage, not cure.",
    image: Thyroid,
    slug: "thyroid-care-program",
  },
  {
    id: 5,
    name: "Body Recomposition",
    desc: "Being underweight despite eating properly is sign of poor gut health. Lets fix the gut and the body.",
    image: Recomposition,
    slug: "body-recomposition-program",
  },
  {
    id: 6,
    name: "Fatty Liver",
    desc: "Flattening a pot belly can be really challenging. Under eating won't work but eating right will.",
    image: FattyLiver,
    slug: "fatty-liver-program",
  },
];

export default programList;

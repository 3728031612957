import React from "react";
import MyDesktop from "../../UtilComponents/MyDesktop";
import Logo from "../../../assest/img/Biomarked-Logo-Horizontal.svg";
import BlackLogo from "../../../assest/img/biomarked_black_header.svg";
import UserLogo from "../../../assest/img/user.svg";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MyLink from "../../UtilComponents/MyLink";
import MyMobile from "../../UtilComponents/MyMobile";
import MyMobile1 from "../../UtilComponents/MyMobile1";
import { Button, Collapse, Divider, Popover } from "antd";
import MyDesktop1 from "../../UtilComponents/MyDesktop1";
import programList from "../../../page/Home/programs/programList";
const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});
const NewHeader = (props) => {
  const expanding = window.location.pathname === "/expanding";
  const background = expanding && "#1A1A1A";
  const logo = expanding ? BlackLogo : Logo;
  const handleLogout = () => {
    props.dispatch({
      type: "user/LOGOUT",
    });
  };
  const role = props?.user?.roles?.id;
  return (
    <React.Fragment>
      <MyDesktop1>
        <div
          style={{
            background: background,
            boxShadow:
              expanding &&
              "0px 4px 6px rgba(0, 0, 0, 0.15), 0px 12px 20px rgba(0, 0, 0, 0.15)",
            position: "sticky",
            zIndex: 10,
          }}
          className="th-br-10 th-header-shadow th-bg-white mx-3 my-3  th-header"
        >
          {props.user.authorized ? (
            <div className="row  p-2 align-items-center">
              <div className="col-md-12 d-flex justify-content-around align-items-center">
                <div className="">
                  <a href={`/`}>
                    <div className="th-img-logo text-center">
                      <img src={logo} alt="Biomarked" />
                    </div>
                  </a>
                </div>
                <MyLink
                  to={role == 1 ? `/manual-money-distribution` : `/dashboard`}
                >
                  <div className="th-btn th-black th-secondary-bg-2 py-2 px-3 th-br-5 th-fw-600 th-18">
                    {role >= 8 ? "Business Center" : "My Bootcamp"}
                  </div>
                </MyLink>

                <div
                  className={"th-small-hr" + (expanding ? "-light" : "")}
                ></div>

                {expanding ? (
                  <a href="#culture">
                    <div className="th-fw-600 text-white">CULTURE</div>
                  </a>
                ) : (
                  <div className="th-fw-600 th-header-dropdown th-18">
                    <div className="th-header-dropbtn">
                      Wellness Program{" "}
                      <i className="fas fa-chevron-down pl-2"></i>
                    </div>
                    <div className="th-header-dropdown-content">
                      <div className="th-header-dropdown-subcontent">
                        <div className="card-body row col-md-12">
                          <div className="col-md-5" style={{ display: "grid" }}>
                            <div className="py-3 th-fw-700 th-text-hint">
                              Not Sure Which Wellness Program Is Best For You?{" "}
                            </div>
                            <div className="th-fw-700 th-text-hint">
                              Get In Touch With Us!
                            </div>
                            <div className="">
                              {/* <button className="th-btn-primary th-pointer th-get-btn  text-white px-4  th-fw-500">
                                Get Biomarked
                              </button> */}
                            </div>
                          </div>
                          <div
                            className="col-md-7"
                            style={{ borderLeft: "1px solid #E9E9EB" }}
                          >
                            {programList?.map((each) => (
                              <MyLink
                                to={`/wellness-program/${each?.slug}`}
                                className="my-2 th-pointer"
                                key={each?.id}
                              >
                                <span>{each?.id === 1 && "Healthy "}</span>
                                {each?.name} Program
                              </MyLink>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={"th-small-hr" + (expanding ? "-light" : "")}
                ></div>
                {expanding ? (
                  <a href="#benefits">
                    <div className="th-fw-600 text-white">BENEFITS</div>
                  </a>
                ) : (
                  <a
                    className={
                      "links th-fw-600 th-18 th-black " +
                      (window.location.pathname.includes("store")
                        ? " th-border-bottom-gradient"
                        : "")
                    }
                    href="/store"
                  >
                    Store
                  </a>
                )}
                <div
                  className={"th-small-hr" + (expanding ? "-light" : "")}
                ></div>
                {expanding ? (
                  <a href="/login">
                    <div className="text-white th-btn th-join-btn py-1 px-2 th-br-5 th-fw-500">
                      JOIN BIOMARKED
                    </div>
                  </a>
                ) : props.user.authorized ? (
                  <div role={"button"} onClick={handleLogout}>
                    <div className="th-fw-600 th-18">Logout</div>{" "}
                  </div>
                ) : (
                  <a className="th-link" href="/login">
                    <div className="th-fw-600 th-18">Login/Signup</div>
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div className="row  py-2 align-items-center">
              <div className="col-md-12 d-flex justify-content-around align-items-center">
                <div className="">
                  <a href={`/`}>
                    <div className="th-img-logo text-center">
                      <img src={logo} alt="Biomarked" />
                    </div>
                  </a>
                </div>
                {expanding ? (
                  <a href="#why">
                    <div className="th-fw-600 text-white">WHY?</div>
                  </a>
                ) : (
                  <a href={`/expanding`}>
                    <div className="th-btn th-black th-secondary-bg-2 py-2 px-3 th-br-5 th-fw-600 th-18">
                      We're Expanding!
                    </div>
                  </a>
                )}
                <div
                  className={"th-small-hr" + (expanding ? "-light" : "")}
                ></div>

                {expanding ? (
                  <a href="#culture">
                    <div className="th-fw-600 text-white">CULTURE</div>
                  </a>
                ) : (
                  <div className="th-fw-600 th-header-dropdown th-18">
                    <div className="th-header-dropbtn">
                      Wellness Program{" "}
                      <i className="fas fa-chevron-down pl-2"></i>
                    </div>
                    <div className="th-header-dropdown-content">
                      <div
                        className="th-header-dropdown-subcontent"
                        style={{ minWidth: "500px" }}
                      >
                        <div className="card-body p-0 px-3 row col-md-12">
                          <div className="col-md-5" style={{ display: "grid" }}>
                            <div className="py-3 th-fw-700 th-text-hint">
                              Not Sure Which Wellness Program Is Best For You?{" "}
                            </div>
                            <div className="th-fw-700 th-text-hint">
                              Get In Touch With Us!
                            </div>
                            <div className="">
                              {/* <Button
                                size="large"
                                className="th-btn-primary th-pointer w-100 text-white h-fw-400"
                              >
                                Get Biomarked
                              </Button> */}
                            </div>
                          </div>
                          <div
                            className="col-md-7"
                            style={{ borderLeft: "1px solid #E9E9EB" }}
                          >
                            {programList?.map((each) => (
                              <MyLink
                                to={`/wellness-program/${each?.slug}`}
                                className="my-2 th-pointer"
                                key={each?.id}
                              >
                                <span>{each?.id === 1 && "Healthy "}</span>
                                {each?.name} Program
                              </MyLink>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={"th-small-hr" + (expanding ? "-light" : "")}
                ></div>
                {expanding ? (
                  <a href="#benefits">
                    <div className="th-fw-600 text-white">BENEFITS</div>
                  </a>
                ) : (
                  <a
                    className={
                      "links th-fw-600 th-18 " +
                      (window.location.pathname.includes("store")
                        ? "th-gradient"
                        : "th-black")
                    }
                    href="/store"
                  >
                    Store
                  </a>
                )}
                <div
                  className={"th-small-hr" + (expanding ? "-light" : "")}
                ></div>
                {expanding ? (
                  <a href="/login">
                    <div className="text-white th-btn th-join-btn py-1 px-2 th-br-5 th-fw-600">
                      JOIN BIOMARKED
                    </div>
                  </a>
                ) : props.user.authorized ? (
                  <a role={"button"} onClick={handleLogout}>
                    <div className="th-fw-600 th-18">Logout</div>{" "}
                  </a>
                ) : (
                  <a className="th-link" href="/login">
                    <div className="th-fw-600 th-18">Login/Signup</div>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </MyDesktop1>
      <MyMobile1>
        <div className="th-br-10 th-header-shadow th-bg-white m-3 th-sticky-header th-header">
          <div className="col-md-12 th-bg-white th-br-10">
            <div className="d-flex   align-items-center justify-content-between">
              <div className="th-mobile-menu py-2">
                <div className="th-pointer th-mobile-menu-button">
                  <i className="fas fa-bars th-18" />
                </div>
                <div className="th-mobile-menu-content">
                  <div className="th-mobile-menu-sub-content th-fw-500 th-header-texts th-16">
                    <div className="p-2">
                      <Collapse
                        defaultActiveKey={[]}
                        style={{ padding: "0px" }}
                        ghost
                        items={[
                          {
                            key: "1",
                            label: "Wellness Programs",
                            children: programList?.map((each) => (
                              <>
                                <a
                                  href={`/wellness-program/${each?.slug}`}
                                  className="links py-2 th-14 "
                                  style={{ color: "black" }}
                                  key={each?.id}
                                >
                                  <span>{each?.id === 1 && "Healthy "}</span>
                                  {each?.name} Program
                                </a>
                                <Divider
                                  className="my-1"
                                  style={{ width: "60px", minWidth: "60px" }}
                                />
                              </>
                            )),
                            className: "th-header th-16",
                          },
                        ]}
                        expandIconPosition="end"
                      />
                    </div>
                    <a href="/expanding">
                      <div className="p-2 th-gradient">We're Expanding!</div>
                    </a>
                    <a href="/store">
                      <div className="p-2 th-black">Store</div>
                    </a>
                    <a href="/coming-soon">
                      <div className="p-2 th-black">About Us</div>
                    </a>
                  </div>
                </div>
              </div>
              <a href="/" className="py-2">
                <img src={Logo} alt="Biomarked" height={30} />
              </a>
              {props?.user?.authorized ? (
                <div className="th-mobile-user-menu">
                  <img className="" src={UserLogo} height={18} alt="user" />
                  <div className="th-mobile-user-menu-content">
                    <div className="th-mobile-user-menu-sub-content th-fw-500 th-header-texts th-16">
                      <div className="p-2 text-center th-18">
                        Hi, {props?.user?.first_name ?? ""} !
                        <div className="d-flex justify-content-center w-100">
                          <Divider
                            className="mt-2 mb-1 text-center"
                            style={{ width: "80%", minWidth: "80%" }}
                          />
                        </div>
                      </div>
                      <MyLink to="/account">
                        <div className="p-2">My Account</div>
                      </MyLink>
                      <MyLink to={"/dashboard"}>
                        <div className="p-2 th-black">
                          {role >= 8 ? "Business Center" : "My Bootcamp"}
                        </div>
                      </MyLink>

                      <div
                        onClick={() => {
                          props.dispatch({
                            type: "user/LOGOUT",
                          });
                        }}
                        className="p-2 th-black"
                      >
                        Sign Out
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Link
                  className={"py-2"}
                  to={props?.user?.authorized ? `/account` : "/login"}
                >
                  <img className="" src={UserLogo} height={18} alt="user" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </MyMobile1>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(NewHeader);

import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MyDesktop1 from "../../UtilComponents/MyDesktop1";
import MyMobile1 from "../../UtilComponents/MyMobile1";
import HorizontalLogo from "../../../assest/img/biomarked_black_header.svg";
import EllipseRight from "../../../assest/img/EllipseRight.svg";
import EllipseLeft from "../../../assest/img/EllipseLeft.svg";
import DashboardLogo from "../../../assest/img/dashboard.svg";
import Curriculum from "../../../assest/img/curriculum.svg";
import Program from "../../../assest/img/program.svg";
import Assessment from "../../../assest/img/assessment.svg";
import Earnings from "../../../assest/img/earnings.svg";
import Account from "../../../assest/img/account.svg";
import Invite from "../../../assest/img/invite.svg";
import MyTeam from "../../../assest/img/myTeam.svg";
import Clients from "../../../assest/img/teams.svg";
import Events from "../../../assest/img/events.svg";
import Podcast from "../../../assest/img/podcast.svg";
import Logout from "../../../assest/img/logout.png";
import greenPattern from "../../../assest/img/greenPattern.svg";

import "./index.css";
import { connect } from "react-redux";
import MyLink from "../../UtilComponents/MyLink";
const mapStateToProps = ({ user, dispatch }) => ({
  dispatch,
  user,
});
export const menuItem = [
  // {
  //   path: "/explorer-dashboard",
  //   name: "Dashboard",
  //   icon: DashboardLogo,
  //   userLevel: [6],
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardLogo,
    userLevel: [5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/curriculum",
    name: "Curriculum",
    icon: Curriculum,
    userLevel: [6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/programs",
    name: "My Program",
    icon: Program,
    userLevel: [5, 6, 7],
  },
  {
    path: "/podcasts",
    name: "Podcasts",
    icon: Podcast,
    userLevel: [5],
  },
  {
    path: "/assessment",
    name: "Assessment",
    icon: Assessment,
    userLevel: [6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/events",
    name: "Event",
    icon: Events,
    userLevel: [5, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/team",
    name: "My Team",
    icon: MyTeam,
    userLevel: [8, 9, 10, 11, 12],
  },
  {
    path: "/clients",
    name: "Clients",
    icon: Clients,
    userLevel: [6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/challenges",
    name: "Challenges",
    icon: Clients,
    userLevel: [6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/earnings",
    name: "Earnings",
    icon: Earnings,
    userLevel: [6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/invite",
    name: "Invite",
    icon: Invite,
    userLevel: [5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    path: "/manual-money-distribution",
    name: "Money Distribution",
    icon: Earnings,
    userLevel: [1],
  },
  {
    path: "/monthly-payout",
    name: "Monthly Payout",
    icon: Earnings,
    userLevel: [1],
  },
  {
    path: "/account",
    name: "My Account",
    icon: Account,
    userLevel: [1, 5, 6, 7, 8, 9, 10, 11, 12],
  },
];
const SideMenuBar = ({ hide, children, user, dispatch }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggleMobile = () => setIsMobileOpen(!isMobileOpen);
  let user_level = user?.roles?.id;

  return (
    <>
      {hide ? (
        <main
          style={{
            backgroundColor:
              window.location.pathname === "/expanding"
                ? "#000000"
                : window.location.pathname.includes("enroll-program")
                ? "#FFFFFF"
                : "#FFFFFF",
          }}
        >
          {children}
        </main>
      ) : (
        <>
          <MyDesktop1>
            <div
              className="th-sidebar-container"
              style={{ overflowY: "hidden" }}
            >
              <div
                style={{
                  width: "250px",
                }}
                className="th-sidebar "
              >
                <img
                  className="position-absolute"
                  style={{
                    left: " 0px",
                    width: "248px",
                    top: "-202px",
                    height: "754px",
                    zIndex: "9",
                  }}
                  src={EllipseRight}
                />
                <img
                  className="position-absolute"
                  style={{
                    left: "-129px",
                    width: "250px",
                    bottom: "0px",
                    zIndex: 9,
                  }}
                  src={greenPattern}
                />
                <div className="th-top-section">
                  <div className="text-center px-2">
                    <img
                      role={"button"}
                      onClick={() => {
                        navigate(`/home`);
                      }}
                      src={HorizontalLogo}
                      style={{ height: "45px" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{ marginLeft: isOpen ? "50px" : "0px" }}
                    className="th-bars"
                  >
                    {/* <div onClick={toggle}>
                      <i className="fas fa-bars" />
                    </div> */}
                  </div>
                </div>
                <div className="pr-4 th-sidebar-menu" style={{ zIndex: "10" }}>
                  {menuItem.map(
                    (item, index) =>
                      item.userLevel.includes(Number(user_level)) && (
                        <MyLink
                          to={item.path}
                          key={index}
                          className="link"
                          activeclassname={
                            window.location.pathname === item.path && "active"
                          }
                          style={{ color: "#fff" }}
                        >
                          <img src={item?.icon} height={20} />
                          <div
                            style={{
                              display: isOpen ? "block" : "none",
                            }}
                            className="th-link-text"
                          >
                            {item.name}
                          </div>
                        </MyLink>
                      )
                  )}
                  <div
                    key={"logout"}
                    className="link th-pointer"
                    style={{ marginRight: "40px", zIndex: 10, color: "#fff" }}
                    onClick={() => {
                      dispatch({
                        type: "user/LOGOUT",
                      });
                    }}
                  >
                    <img src={Logout} height={20} />
                    <div
                      style={{
                        display: isOpen ? "block" : "none",
                      }}
                      className="th-link-text"
                    >
                      Logout
                    </div>
                  </div>
                </div>
              </div>
              <main
                style={{
                  backgroundColor:
                    window.location.pathname === "/expanding"
                      ? "#000000"
                      : "#FFFFFF",
                }}
                className="position-relative th-sidebar-container-padding"
              >
                {children}
              </main>
            </div>
          </MyDesktop1>
          <MyMobile1>
            <div className="th-mobile-sidebar position-relative">
              <main
                style={{
                  backgroundColor:
                    window.location.pathname === "/expanding"
                      ? "#000000"
                      : "#FFFFFF",
                }}
                className="th-sidebar-container-padding"
              >
                {children}
              </main>
            </div>
          </MyMobile1>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps)(SideMenuBar);

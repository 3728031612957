/** @format */

import axios from "axios";
import setAuthToken from "../global/setAuthToken";
// import store from "store";

export async function login(email1, password1) {
  const value = { email: email1, password: password1 };

  return axios
    .post(`/token-auth/`, value)
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.access);
        setAuthToken(response.data.access);
        return response.data;
      } else if (response.status !== 200) {
        return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function otpLogin(contact, otp, method) {
  let authorized1 = false;
  return axios
    .post(`apiV1/verify-otp/`, {
      contact: contact,
      otp: otp,
      method: method,
    })
    .then((response) => {
      authorized1 = true;
      const token = response.data.token?.toString();
      const expirationDate = new Date(new Date().getTime() + 3600 * 10000);
      localStorage.setItem("token", token);
      localStorage.setItem("expirationDate", expirationDate);
      setAuthToken(token);
      return {
        authorized: authorized1,
        token: response.data.token,
      };
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function currentAccount() {
  let authorized1 = false;
  return axios
    .get(`/apiV1/current-user/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      authorized1 = true;

      const { data } = response;
      let userDetails = { ...data, authorized: authorized1 };
      return {
        ...userDetails,
      };
    })
    .catch((error) => {});
}

export async function logout() {
  localStorage.clear();
  // window.location.reload();
}

import { all, takeEvery, put, call } from "redux-saga/effects";
import { history } from "../../index";
import { currentAccount, logout } from "../../services/users";
import actions from "./actions";

export function* LOGIN({ payload }) {
  // const { email, password, navigate } = payload;
  // yield put({
  //   type: "user/SET_STATE",
  //   payload: {
  //     loading: true,
  //     wrong: false,
  //   },
  // });
  // const success = yield call(login, email, password);
  if (payload) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong: true,
        login: true,
      },
    });
  }
}

export function* OTP_LOGIN({ payload }) {
  // const { contact, otp, method } = payload;
  // yield put({
  //   type: "user/SET_STATE",
  //   payload: {
  //     loading: true,
  //   },
  // });
  // const success = yield call(otpLogin, contact, otp, method);
  console.log(payload, "success");
  if (payload) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  // let values = args;
  yield put({
    type: "settings/GET_SETTINGS",
  });
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  const response = yield call(currentAccount);
  if (response) {
    console.log(response, "iuyti");
    yield put({
      type: "user/SET_STATE",
      payload: {
        ...response,
      },
    });
    // navigate(`/`);
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        login: false,
      },
    });
  }
}

export function* LOGOUT() {
  yield call(logout);
  yield history.push("/home");
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      contact: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
      userdata: {},
      wrong: false,
      login: false,
      gender_choice: "",
      city: "",
      token: "",
      user: "",
    },
  });
  window.location.reload();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.OTP_LOGIN, OTP_LOGIN),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}

import React, { useContext } from "react";
import "./index.css";
import { SnackbarContext } from "./SnackbarContext";

const Snackbar = ({ closeSnackbar }) => {
  const { isOpen, message, severity } = useContext(SnackbarContext);
  console.log(severity);
  return (
    <div className="d-flex justify-content-center snackbar-container w-100">
      <div className={`snackbar ${isOpen ? "show" : ""}`}>
        <div className="message">
          {severity === "success" && (
            <i class="fas fa-check-circle pr-2 th-18 text-success"></i>
          )}
          {severity === "error" && (
            <i class="fas fa-times-circle pr-2 th-18 text-danger"></i>
          )}
          {severity === "warning" && (
            <i class="fas fa-exclamation-circle pr-2 th-18 text-warning"></i>
          )}
          {message}
        </div>
      </div>
    </div>
  );
};

export default Snackbar;

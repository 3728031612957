const actions = {
  SET_STATE: "user/SET_STATE",
  LOGIN: "user/LOGIN",
  REGISTER: "user/REGISTER",
  OTP_LOGIN: "user/OTP_LOGIN",
  LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
  LOGOUT: "user/LOGOUT",
};

export default actions;
